import axios from 'axios';

const apiGet = async (endpoint: string) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`);
  } catch (error) {
    return Promise.reject(error);
  }
};

const apiPost = async (endpoint: string, { data }: any) => {
  try {
    return await axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { apiGet, apiPost };
