import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Toolbar, Container } from '@material-ui/core';

const LandingPage: React.FC<RouteComponentProps> = () => {
  return (
    <Container>
      <Toolbar />
      Welcome to video okhati.
    </Container>
  );
};

export default LandingPage;
