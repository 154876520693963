import React from "react";

import { Box, Container } from "@material-ui/core";

function ErrorMessage() {
  return (
    <Container>
      <Box
        fontSize="20"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        Oops! something went wrong.
      </Box>
    </Container>
  );
}

export default ErrorMessage;
