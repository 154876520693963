import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import okhatiLogo from "./eokhati_full_green.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "transparent",
    },
    toolbar: {
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    logoImg: {
      width: 125,
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        padding: "1em",
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  return (
    <AppBar className={classes.container} position="absolute" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <img className={classes.logoImg} src={okhatiLogo} alt="okhati-logo" />
      </Toolbar>
    </AppBar>
  );
}
