import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const Container = styled('aside')(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: '0.5em',
  right: '0.5em',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 260,
  maxHeight: 500,
  [theme.breakpoints.down('xs')]: {
    maxHeight: 60,
    width: 96,
    fontSize: '8px',
  },
}));

const HideContainer = styled('div')(() => ({
  display: 'none',
}));

const ScrollContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <Container>
      <ScrollContainer>
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          // disable click on participant
          onClick={() => {}}
          // onClick={() => setSelectedParticipant(localParticipant)}
        />
        <HideContainer>
          {participants.map(participant => (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={selectedParticipant === participant}
              onClick={() => setSelectedParticipant(participant)}
            />
          ))}
        </HideContainer>
      </ScrollContainer>
    </Container>
  );
}
