import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import { apiGet } from '../api/request';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  appointmentStatus: any;
  getAppointmentStatus(token: string): void;
  fetchingAppointment: boolean;
  fetchingAppointmentError: boolean;
  twilioToken: string | null;
  getTwilioToken(token: string): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  const [appointmentStatus, setAppointmentStatus] = React.useState<any>(null);
  const [fetchingAppointment, setFetchingAppointment] = useState(false);
  const [fetchingAppointmentError, setFetchingAppointmentError] = useState(false);
  const [twilioToken, setTwilioToken] = React.useState<string | null>(null);

  let contextValue = {
    error,
    setError,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    appointmentStatus,
    fetchingAppointment,
    fetchingAppointmentError,
    twilioToken,
  } as StateContextType;

  const getTwilioToken: StateContextType['getTwilioToken'] = async (urlToken: string) => {
    try {
      const { data } = await apiGet(`/appointments/${urlToken}/getTwilioToken/`);
      setTwilioToken(data.token);
    } catch (e) {
      console.log('Could not fetch getTwilioToken', e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAppointmentStatus: StateContextType['getAppointmentStatus'] = async token => {
    try {
      setFetchingAppointment(true);
      setFetchingAppointmentError(false);
      const { data } = await apiGet(`/appointments/${token}/status/`);
      setAppointmentStatus(data);
    } catch (e) {
      setFetchingAppointmentError(e);
      setFetchingAppointment(false);
    }
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getAppointmentStatus, getTwilioToken }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
