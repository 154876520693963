import React from 'react';
import { Toolbar, Box, Button, Container } from '@material-ui/core';
import moment from 'moment';
import startCase from 'lodash.startcase';

import { useAppState } from '../../state';
import getGreetingTime from '../../helpers/getGreetingTime';

interface PaymentFlowProps {
  requirePayment: boolean;
  showRevisitInfo: boolean;
  currentUrl?: string;
}

const PaymentFlow: React.FC<PaymentFlowProps> = ({ requirePayment, currentUrl, showRevisitInfo }) => {
  const {
    appointmentStatus: { from, client, videoService, host, bookingId },
  } = useAppState();

  const onPay = () => {
    const urlParams = `/?pId=${videoService.id}&pType=service&pName=video_consultation&bId=${bookingId}&spId=${host.id}&spT=${host.title}&spFName=${host.firstName}&spLName=${host.lastName}&price=${videoService.price}&rd=${currentUrl}&t=${from}`;
    window.location.href = process.env.REACT_APP_PAYMENT_URL + urlParams;
  };

  return (
    <Container>
      {/* Add menubar spacing with Toolbar */}
      <Toolbar />
      <Box display="flex" justifyContent="center" flexDirection="column" component="div" mt={12}>
        <Box fontSize={24} textAlign="center" component="h2" mt={0} mb={1}>
          {getGreetingTime(moment(from))} {startCase(client.firstName)},
        </Box>
        <Box fontSize={20} textAlign="center" component="p" m={0}>
          You have a video appointment scheduled with {host.title}
          {!host.title.includes('.') && '.'} {startCase(host.firstName)} for
        </Box>
        <Box
          fontSize={20}
          fontWeight="bold"
          style={{ textDecoration: 'underline' }}
          textAlign="center"
          component="p"
          m={0}
        >
          {moment(from).format('LLLL')}
        </Box>
        {showRevisitInfo && (
          <Box my={4}>
            <Box fontSize={18} textAlign="center" component="p" m={0}>
              Please come back again
            </Box>
            <Box fontSize={18} textAlign="center" component="p" m={0}>
              {moment(from).format('LLLL')}
            </Box>
            <Box fontSize={18} textAlign="center" component="p" m={0}>
              to meet {host.title}
              {!host.title.includes('.') && '.'} {startCase(host.firstName)}
            </Box>
          </Box>
        )}
        {requirePayment && (
          <>
            <Box fontSize={18} color="#db2323" textAlign="center" component="p" m={0} mt={8}>
              The appointment is not confirmed yet.
            </Box>
            <Box fontSize={18} textAlign="center" component="p" m={0}>
              To confirm the appointment
            </Box>
            <Box fontSize={18} textAlign="center" component="p" m={0}>
              pay for the consulting charge by{' '}
              {moment(from)
                .subtract(24, 'hours')
                .format('LLLL')}
            </Box>
            <Box component="div" textAlign="center" my={2}>
              <Button onClick={onPay} fullWidth={false} variant="contained" color="primary">
                {/* Pay रू {paymentStatus.amount} now */}
                Proceed to payment
              </Button>
            </Box>
          </>
        )}

        <Box fontSize={18} textAlign="center" component="p" m={0} mt={4}>
          If you want to change date/time or cancel the appointment
        </Box>
        <Box fontSize={18} textAlign="center" component="p" m={0}>
          call +977 9817044009
        </Box>
      </Box>
    </Container>
  );
};

export default PaymentFlow;
