import * as React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import moment from 'moment';
import { Box, Container } from '@material-ui/core';
import startCase from 'lodash.startcase';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useHeight from '../../hooks/useHeight/useHeight';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import Room from '../../components/Room/Room';
import Controls from '../../components/Controls/Controls';
import ReconnectingNotification from '../../components/ReconnectingNotification/ReconnectingNotification';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import getGreetingTime from '../../helpers/getGreetingTime';
import FullpageSpinner from '../../components/FullpageSpinner';
import VideoEnding from '../ClientFlow/VideoEnding';
import { apiPost } from '../../api/request';

const ClientFlow: React.FC<RouteComponentProps> = () => {
  const [hostStatus, setHostStatus] = React.useState('offline');
  const roomState = useRoomState();
  const {
    appointmentStatus: { client, host, from, duration },
  } = useAppState();

  const height = useHeight();
  const { isConnecting, connect, isVideoEnded } = useVideoContext();
  const { twilioToken } = useAppState();
  const { token } = useParams();
  const enableAutoJoin = true;

  const isParticipantLate =
    moment().valueOf() <
      moment(from)
        .add(duration + 30, 'minutes')
        .valueOf() &&
    moment().valueOf() >=
      moment(from)
        .add(duration, 'minutes')
        .valueOf();

  React.useEffect(() => {
    const clientPing = async () => {
      const { data } = await apiPost(`appointments/${token}/clientPing/`, {
        data: {
          status: roomState !== 'disconnected' ? 'inRoom' : 'online',
        },
      });
      setHostStatus(data.hostStatus);
    };
    clientPing();

    const interval = setInterval(() => {
      clientPing();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomState]);

  const onClickJoin = (event?: any) => {
    connect(twilioToken);
  };

  React.useEffect(() => {
    if (roomState === 'disconnected' && twilioToken && ['online', 'inRoom'].includes(hostStatus) && enableAutoJoin) {
      onClickJoin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioToken, hostStatus]);

  if (isVideoEnded) {
    return <VideoEnding clientName={startCase(client.firstName)} token={token} />;
  }

  return (
    <Box component="div" position="relative">
      {isConnecting && <FullpageSpinner />}
      {roomState === 'disconnected' ? (
        <Container>
          <Box
            component="div"
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={3}
          >
            <Box component="div">
              <Box maxWidth="640px" mt={8}>
                <Box component="div" mb={4}>
                  <Box fontSize={24} component="h2" m={0} mb={1}>
                    {getGreetingTime(moment())} {startCase(client.firstName)},
                  </Box>
                  {['online', 'inRoom'].includes(hostStatus) && !enableAutoJoin ? (
                    <Box fontSize={20} component="p" m={0}>
                      {host.title}
                      {!host.title.includes('.') && '.'} {startCase(host.firstName)} is waiting for you...
                    </Box>
                  ) : isParticipantLate ? (
                    <Box mr={2}>
                      <Box fontSize={20} component="p" m={0}>
                        You seem to be little late. Do not worry, your appointment will start as soon as {host.title}
                        {!host.title.includes('.') && '.'} {startCase(host.firstName)} will start call.
                      </Box>
                      <Box fontSize={20} component="p" mt={2}>
                        If you still have technical issues, call Okhati support at +977-9817044009 or send us message in
                        Facebook.
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box fontSize={20} component="p" m={0}>
                        Please wait a bit.
                      </Box>
                      <Box fontSize={20} component="p" m={0}>
                        {host.title}
                        {!host.title.includes('.') && '.'} {startCase(host.firstName)} will soon join the video chat...
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box position="relative" paddingBottom="56.25%">
                <Box component="main" position="absolute" top="0" left="0" width="100%" height="100%" my={2}>
                  <LocalVideoPreview />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      ) : (
        <Box
          component="main"
          display="flex"
          alignItems="center"
          width="100%"
          height={height}
          style={{ overflowY: 'hidden' }}
        >
          <Room />
        </Box>
      )}
      <Controls />
      <ReconnectingNotification />
    </Box>
  );
};

export default ClientFlow;
