import React from "react";
import { Portal, Box, CircularProgress } from "@material-ui/core";

function FullpageSpinner() {
  return (
    <Portal>
      <Box
        top="0"
        position="fixed"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex="999"
        width="100%"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    </Portal>
  );
}

export default FullpageSpinner;
