import React from 'react';
import { Router } from '@reach/router';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './theme';
import AppStateProvider from './state';
import MenuBar from './containers/MenuBar';
import ClientFlow from './containers/ClientFlow';
import LandingPage from './containers/LandingPage';
import NotFound from './containers/NotFound';
import VideoDirect from './containers/VideoDirect';
import ErrorBoundary from './components/ErrorBoundary';

export default function App() {
  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MenuBar />
        <AppStateProvider>
          <Router>
            <LandingPage path="/" />
            <ClientFlow path="/:token" />
            <VideoDirect path="/host/:token" />
            <NotFound default />
          </Router>
        </AppStateProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
}
