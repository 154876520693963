import React from 'react';
import { useParams } from '@reach/router';
import { Box } from '@material-ui/core';
import VideoEnding from '../ClientFlow/VideoEnding';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useHeight from '../../hooks/useHeight/useHeight';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import Room from '../../components/Room/Room';
import Controls from '../../components/Controls/Controls';
import ReconnectingNotification from '../../components/ReconnectingNotification/ReconnectingNotification';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import FullpageSpinner from '../../components/FullpageSpinner';
import { apiPost } from '../../api/request';

function VideoWindow() {
  const roomState = useRoomState();
  const height = useHeight();
  const { isConnecting, connect, isVideoEnded } = useVideoContext();
  const { token } = useParams();

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.data && event.data.messageType === 'token') {
        connect(event.data.twilioToken);
      }
    };
    window.addEventListener('message', listener, false);
    return () => window.removeEventListener('message', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hostPing = () => {
      apiPost(`appointments/${token}/hostPing/`, {
        data: {
          status: roomState !== 'disconnected' ? 'inRoom' : 'online',
        },
      });
    };
    hostPing();
    const interval = setInterval(() => {
      hostPing();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomState]);

  const endCall = () => {
    window.parent.postMessage(
      {
        messageType: 'control',
        control: {
          EndCall: true,
        },
      },
      '*'
    );
  };

  if (isVideoEnded) {
    return <VideoEnding />;
  }
  return (
    <Box component="div" position="relative">
      {isConnecting && <FullpageSpinner />}
      <Box
        component="main"
        display="flex"
        alignItems="center"
        width="100%"
        height={height}
        style={{ overflowY: 'hidden' }}
      >
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
      </Box>
      <Controls endCall={endCall} />
      <ReconnectingNotification />
    </Box>
  );
}

export default VideoWindow;
