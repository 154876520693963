import React, { Component } from "react";
import { Button, Typography, Box } from "@material-ui/core";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: Boolean;
  hasRetried: Boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false, hasRetried: false };

  static getDerivedStateFromError = () => ({ hasError: true });

  componentDidCatch = () => {
    if (this.state.hasRetried) {
      window.location.reload();
      return;
    }
    this.setState({ hasError: true });
  };

  resetState = () => {
    this.setState({ hasError: false, hasRetried: true });
  };
  render() {
    if (this.state.hasError) {
      return (
        <Box
          component="div"
          display="flex"
          height="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" component="h1" align="center">
            Oops! something went wrong.
          </Typography>
          <Button color="primary" onClick={this.resetState}>
            Try again
          </Button>
        </Box>
      );
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;
