import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import lowerCase from 'lodash.lowercase';

import { useAppState } from '../../state';
import VideoFlow from './VideoFlow';
import PaymentFlow from './PaymentFlow';
import CancelledOrExpiredFlow from './CancelledOrExpiredFlow';
import FullpageSpinner from '../../components/FullpageSpinner';
import ErrorMessage from './ErrorMessage';

const ClientFlow: React.FC<RouteComponentProps> = props => {
  const { getAppointmentStatus, appointmentStatus, fetchingAppointment, fetchingAppointmentError } = useAppState();
  const { token } = useParams();

  React.useEffect(() => {
    getAppointmentStatus(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetchingAppointmentError) return <ErrorMessage />;
  if (fetchingAppointment && !appointmentStatus) return <FullpageSpinner />;
  if (!appointmentStatus) return null;

  if (
    appointmentStatus.paymentInfo?.required &&
    ((appointmentStatus.status === 'active' && !Boolean(appointmentStatus.paymentStatus)) ||
      (appointmentStatus.status === 'active' &&
        Boolean(appointmentStatus.paymentStatus) &&
        lowerCase(appointmentStatus.paymentStatus.trxStatus) !== 'success'))
  ) {
    return (
      <PaymentFlow
        requirePayment={appointmentStatus.paymentInfo.required}
        currentUrl={props?.location?.href}
        showRevisitInfo={false}
      />
    );
  } else if (appointmentStatus.status === 'cancelled') {
    return <CancelledOrExpiredFlow status="cancelled" />;
  } else if (appointmentStatus.status === 'expired') {
    return <CancelledOrExpiredFlow status="expired" />;
  } else {
    return <VideoFlow />;
  }
};

export default ClientFlow;
