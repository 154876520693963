import React from 'react';
import { RouteComponentProps } from '@reach/router';

import VideoWindow from './VideoWindow';
import { useAppState } from '../../state';
import { VideoProvider } from '../../components/VideoProvider';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from '../../utils/generateConnectionOptions/generateConnectionOptions';
import UnsupportedBrowserWarning from '../../components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import MediaPermissionWarning from '../../components/MediaPermissionWarning/MediaPermissionWarning';
import '../../types';

const VideoDirect: React.FC<RouteComponentProps> = () => {
  const { error, setError, settings } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  return (
    <UnsupportedBrowserWarning>
      <MediaPermissionWarning>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <VideoWindow />
        </VideoProvider>
      </MediaPermissionWarning>
    </UnsupportedBrowserWarning>
  );
};

export default VideoDirect;
