import React from 'react';
import { Toolbar, Box, Container } from '@material-ui/core';
import moment from 'moment';

import { useAppState } from '../../state';

function CancelledOrExpiredFlow({ status }: any) {
  const {
    appointmentStatus: { from },
  } = useAppState();
  return (
    <Container>
      {/* Add menubar spacing with Toolbar */}
      <Toolbar />
      <Box display="flex" justifyContent="center" flexDirection="column" component="div" mt={12}>
        <Box fontSize={20} textAlign="center" component="p" m={0}>
          This video appointment scheduled for
        </Box>

        <Box
          fontSize={20}
          fontWeight="bold"
          style={{ textDecoration: 'underline' }}
          textAlign="center"
          component="p"
          m={0}
        >
          {moment(from).format('LLLL')}
        </Box>
        <Box fontSize={20} textAlign="center" component="p" m={0}>
          has already {status}.
        </Box>
      </Box>
    </Container>
  );
}

export default CancelledOrExpiredFlow;
