import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      backgroundColor: '#f00',
      '&:hover': {
        backgroundColor: '#c80000',
      },
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton({ endCall }: { endCall?: any }) {
  const classes = useStyles();
  const { room, setIsVideoEnded } = useVideoContext();

  return (
    <Tooltip
      title={'End Call'}
      onClick={() => {
        room.disconnect();
        setIsVideoEnded(true);
        endCall && endCall();
      }}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab}>
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
