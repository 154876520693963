import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Toolbar, Container } from "@material-ui/core";

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <Container>
      <Toolbar />
      Sorry the page does not exist!
    </Container>
  );
};

export default NotFound;
