import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      background: '#0c0',
      display: 'inline-block',
      marginRight: '3px',
      [theme.breakpoints.down('xs')]: {
        width: '5px',
        height: '5px',
      },
    },
    isReconnecting: {
      background: '#ffb100',
    },
  })
);

export default function ParticipantConnectionIndicator({ participant }: { participant: Participant }) {
  const isReconnecting = useParticipantIsReconnecting(participant);
  const classes = useStyles();
  return (
    <Tooltip title={isReconnecting ? 'Participant is reconnecting' : 'Participant is connected'}>
      <span className={clsx(classes.indicator, { [classes.isReconnecting]: isReconnecting })}></span>
    </Tooltip>
  );
}
