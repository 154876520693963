import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { CssBaseline } from '@material-ui/core';
// import { MuiThemeProvider } from '@material-ui/core/styles';
// import AppStateProvider, { useAppState } from './state';
// import { BrowserRouter as Router, Route, Switch, useParams /* Redirect */ } from 'react-router-dom';
// import ErrorDialog from './components/ErrorDialog/ErrorDialog';
// import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';
// import theme from './theme';
// import './types';
// import { VideoProvider } from './components/VideoProvider';
// import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

// const VideoApp = () => {
//   let { token } = useParams();
//   const { error, setError, settings } = useAppState();
//   const connectionOptions = generateConnectionOptions(settings);

//   return (
//     <UnsupportedBrowserWarning>
//       <VideoProvider options={connectionOptions} onError={setError}>
//         <ErrorDialog dismissError={() => setError(null)} error={error} />
//         <App token={token} />
//       </VideoProvider>
//     </UnsupportedBrowserWarning>
//   );
// };

ReactDOM.render(<App />, document.getElementById('root'));
