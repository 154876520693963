import React from 'react';
import moment from 'moment';
import { useParams } from '@reach/router';
import { VideoProvider } from '../../components/VideoProvider';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import VideoOnboarding from './VideoOnboarding';
import { useAppState } from '../../state';
import PaymentFlow from './PaymentFlow';
import CancelledOrExpiredFlow from './CancelledOrExpiredFlow';
import generateConnectionOptions from '../../utils/generateConnectionOptions/generateConnectionOptions';
import UnsupportedBrowserWarning from '../../components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import MediaPermissionWarning from '../../components/MediaPermissionWarning/MediaPermissionWarning';
import OnboardToNativeApp from '../../components/OnboardToNativeApp';
import '../../types';

const VideoFlow: React.FC = () => {
  const [currTime, setCurrTime] = React.useState(moment());
  const { error, setError, settings, appointmentStatus, twilioToken, getTwilioToken } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);
  const { from, duration } = appointmentStatus;
  const { token } = useParams();

  const maxDelayDuration = duration + 30;

  React.useEffect(() => {
    const updateTwilioToken = () => {
      if (
        appointmentStatus &&
        !twilioToken &&
        moment().valueOf() >
          moment(from)
            .subtract(5, 'minutes')
            .valueOf() &&
        moment().valueOf() <
          moment(from)
            .add(maxDelayDuration, 'minutes')
            .valueOf()
      ) {
        getTwilioToken(token);
      }
    };
    updateTwilioToken();
    const timer = setInterval(() => {
      if (twilioToken) {
        clearInterval(timer);
        return;
      }
      updateTwilioToken();
    }, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioToken]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (currTime.valueOf() < moment(from).valueOf()) {
        setCurrTime(moment);
      } else {
        clearTimeout(timeout);
      }
    }, 30000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currTime]);

  const isVideoSetupTime =
    currTime.valueOf() >
      moment(from)
        .subtract(5, 'minutes')
        .valueOf() &&
    currTime.valueOf() <
      moment(from)
        .add(maxDelayDuration, 'minutes')
        .valueOf();

  const isSessionOver =
    currTime.valueOf() >
    moment(from)
      .add(maxDelayDuration, 'minutes')
      .valueOf();

  return (
    <>
      {isVideoSetupTime ? (
        <OnboardToNativeApp>
          <UnsupportedBrowserWarning>
            <MediaPermissionWarning>
              <VideoProvider options={connectionOptions} onError={setError}>
                <ErrorDialog dismissError={() => setError(null)} error={error} />
                <VideoOnboarding />
              </VideoProvider>
            </MediaPermissionWarning>
          </UnsupportedBrowserWarning>
        </OnboardToNativeApp>
      ) : (
        <>
          {isSessionOver ? (
            <CancelledOrExpiredFlow status="ended" />
          ) : (
            <PaymentFlow requirePayment={false} showRevisitInfo={true} />
          )}
        </>
      )}
    </>
  );
};

export default VideoFlow;
