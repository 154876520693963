import React from 'react';
import { Container, Typography, Paper, Grid, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { hasMediaPermissions } from '../../utils';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
    fontSize: 24,
  },
  info: {
    fontSize: 20,
  },
});

export default function({ children }: { children: React.ReactElement }) {
  const [hasMediaAccess, setHasMediaAccess] = React.useState(false);
  const [isInitializing, setIsInitializing] = React.useState(true);

  React.useEffect(() => {
    (async function() {
      setIsInitializing(true);
      const acessState = await hasMediaPermissions();
      setHasMediaAccess(acessState);
      setIsInitializing(false);
    })();
  }, []);

  const classes = useStyles();

  if (isInitializing) {
    return null;
  }

  if (!hasMediaAccess) {
    return (
      <Container>
        <Toolbar />
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                Video Okhati can't access your camera or microphone
              </Typography>
              <Typography className={classes.info}>
                Please, reload this page and allow the media permissions to make the video call possible
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
