import React from 'react';
import { isAndroid } from 'react-device-detect';
import { useParams } from '@reach/router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  appButton: {
    background: '#009654',
    border: 0,
    borderRadius: 3,
    color: '#fff',
    padding: '8px 24px !important',
    margin: '8px',
  },
  dialogContent: {
    padding: '24px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

function OnboardToNativeApp({ children }: any) {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [runInBrowser, setRunInBrowser] = React.useState<boolean>(false);

  const { token } = useParams();

  React.useEffect(() => {
    if (isAndroid) {
      setShowDialog(true);
      return;
    }
    setRunInBrowser(true);
  }, []);

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={showDialog}
        aria-labelledby="onboard-dialog-title"
        aria-describedby="onboard-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogContent className={classes.dialogContent}>
          <Link
            underline="none"
            className={classes.appButton}
            href={`intent://${token}/#Intent;scheme=okhati;package=com.okhatiapp;S.browser_fallback_url='';end`}
          >
            Open with Okhati App
          </Link>
          <Button
            onClick={() => {
              setShowDialog(false);
              setRunInBrowser(true);
            }}
            color="primary"
          >
            Continue in browser
          </Button>
        </DialogContent>
      </Dialog>

      {runInBrowser && children}
    </>
  );
}

export default OnboardToNativeApp;
